import { constants } from '@/globals'
import { useAuthenticationStore, useCustomerStore } from '@/shared/authentication'
import { useUserStore } from '@/shared/authorization'

import localforage from 'localforage'
import { storeToRefs } from 'pinia'

import type { NavigationGuard } from 'vue-router'

export function useAuthenticationGuard(): NavigationGuard {
  return async (to) => {
    const { checkAuth0Session } = useAuthenticationStore()
    const userStore = useUserStore()
    const { getMe } = userStore
    const { user } = storeToRefs(userStore)
    const { setActiveCustomer, clearActiveCustomer } = useCustomerStore()
    const redirect = await localforage.getItem<string>(constants.LOCAL_STORAGE_AUTHENTICATION_REDIRECT)

    async function updateActiveCustomerByParams() {
      if (!to.params.customerId) {
        clearActiveCustomer()
        return
      }

      return setActiveCustomer(to.params.customerId as string)
    }

    async function updateCurrentUser() {
      if (user.value) {
        return
      }

      return getMe()
    }

    async function clearRedirect() {
      if (redirect) {
        return localforage.removeItem(constants.LOCAL_STORAGE_AUTHENTICATION_REDIRECT)
      }
    }

    try {
      await checkAuth0Session()
      await Promise.all([
        clearRedirect(),
        updateActiveCustomerByParams(),
        updateCurrentUser(),
      ])

      if (redirect) {
        return { path: redirect }
      }
    } catch {
      return false
    }
  }
}
