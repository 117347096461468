import { useUserStore } from '@/shared/authorization'

import type { NavigationGuard } from 'vue-router'

export function useIsAdminGuard(): NavigationGuard {
  return async () => {
    const { isAdminUser } = useUserStore()

    if (!isAdminUser) {
      return false
    }
  }
}
