import { useUserStore } from '@/shared/authorization'
import { I18N_DEFAULT_LOCALE } from '@/App.config'

import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'
import axios from 'axios'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { createI18n } from 'vue-i18n'
import { setLocale as setYupLocale } from 'yup'

import type { MessageFunction } from 'vue-i18n'
import type { LocaleObject } from 'yup'
import type { App, Ref } from 'vue'
import type { User } from '@/model/settings'

type JSONLocaleModule = { default: Record<string, MessageFunction> }

const YUP_PREFIX = 'yup.'

const i18n = createI18n({
  legacy: false,
  locale: I18N_DEFAULT_LOCALE,
  fallbackLocale: I18N_DEFAULT_LOCALE,
})

function setLocaleLanguage(locale: string) {
  i18n.global.locale.value = locale
  axios.defaults.headers.common['Accept-Language'] = locale
  document.documentElement.setAttribute('lang', locale)
}

async function loadLocaleMessages(locale: string) {
  const loadLanguage = retriableLazyImport<JSONLocaleModule>(() => import(`./locales/${locale}.json`))
  const messages = await loadLanguage()
  const yupMessageKeys = Object.keys(messages.default).filter((key) => key.startsWith(YUP_PREFIX))
  const yupMessages: Record<string, Record<string, string>> = {}

  i18n.global.setLocaleMessage(locale, messages.default)

  for (const key of yupMessageKeys) {
    const [, type, validator] = key.split('.')
    if (!yupMessages[type]) {
      yupMessages[type] = {}
    }

    yupMessages[type][validator] = i18n.global.t(key, {
      /* eslint-disable no-template-curly-in-string */
      path: '${path}',
      values: '${values}',
      min: '${min}',
      max: '${max}',
      regex: '${regex}',
      length: '${length}',
      /* eslint-enable no-template-curly-in-string */
    })
  }

  setYupLocale(yupMessages as LocaleObject)
}

export default (app: App) => {
  const { user }: { user: Ref<User | undefined> } = storeToRefs(useUserStore())

  app.use(i18n, {})

  watch(user, async (value) => {
    if (value && value.language) {
      await loadLocaleMessages(value.language)
      return setLocaleLanguage(value.language)
    }
  })

  return loadLocaleMessages(I18N_DEFAULT_LOCALE)
}
