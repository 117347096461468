import { constants } from '@/globals'

import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

export default {
  path: 'main-user',
  meta: {
    navigation: {
      labelKey: 'app.main_user.pages.details.title',
      targetName: constants.ROUTES_MAIN_USER,
    },
  },
  children: [
    {
      path: '',
      component: retriableLazyImport(() => import('./pages/Details.vue')),
      name: constants.ROUTES_MAIN_USER,
    },
  ],
} as RouteRecordRaw
