import { constants } from '@/globals'

import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

export default {
  path: 'modules',
  meta: {
    navigation: {
      labelKey: 'app.modules.pages.overview.title',
      targetName: constants.ROUTES_MODULES,
    },
  },
  children: [
    {
      path: '',
      component: retriableLazyImport(() => import('./pages/Overview.vue')),
      name: constants.ROUTES_MODULES,
    },
    {
      path: ':moduleId',
      component: retriableLazyImport(() => import('./pages/Details.vue')),
      name: constants.ROUTES_MODULE,
    },
  ],
} as RouteRecordRaw
