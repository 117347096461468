import type { Directive, DirectiveBinding } from 'vue'

const ATTRIBUTE_NAME = 'data-test'

function setTestAttributeSingle(el: Element, name?: string) {
  const testName = name ?? el.getAttribute('name') ?? el.getAttribute('id')

  if (!testName) {
    return
  }

  el.setAttribute(ATTRIBUTE_NAME, testName)
}

function setTestAttributesDeep(root: Element, binding: DirectiveBinding<string | undefined>) {
  setTestAttributeSingle(root, binding.value)

  const inputs = root.querySelectorAll('input, select, textarea, fieldset')

  for (const input of inputs) {
    setTestAttributeSingle(input)
  }
}

export const TestDirective: Directive<Element, string | undefined> = {
  beforeMount: setTestAttributesDeep,
  updated: setTestAttributesDeep,
}

export default TestDirective
