import { addMethod, number, string } from 'yup'

type MessageFunctionParams = {
  label: string
}
type MessageFunction<T = MessageFunctionParams> = (messageParams: T) => string

declare module 'yup' {
  interface StringSchema<TType, TContext, TDefault, TFlags> {
    validateBlankSpaces(message?: MessageFunction | string): this
    emptyStringToUndefined(): this
    nullToUndefined(): this
  }

  interface NumberSchema<TType, TContext, TDefault, TFlags> {
    nullToUndefined(): this
    emptyNaNToUndefined(): this
    nanToUndefined(): this
  }
}

addMethod(string, 'emptyStringToUndefined', function emptyStringToUndefined() {
  return this.transform((value: string|unknown) => value === '' ? undefined : value)
})

addMethod(string, 'validateBlankSpaces', function validateBlankSpaces(message?: MessageFunction | string) {
  return this.matches(/^(?!\s+$).*/, message)
})

addMethod(number, 'nullToUndefined', function nullToUndefined() {
  return this.transform((value: null|unknown) => value === null ? undefined : value)
})

addMethod(string, 'nullToUndefined', function nullToUndefined() {
  return this.transform((value: null|unknown) => value === null ? undefined : value)
})

addMethod(number, 'emptyNaNToUndefined', function emptyNaNToUndefined() {
  return this.transform((value: string|undefined, originalValue: string|undefined) => {
    return typeof originalValue === 'string' && ['', '-'].includes(originalValue)
      ? undefined
      : value
  })
})

addMethod(number, 'nanToUndefined', function nanToUndefined() {
  return this.transform((value: unknown) => Number.isNaN(value) ? undefined : value)
})
