import { useAuthenticationStore } from '@/shared/authentication'

import axios from 'axios'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'

import type { AxiosError } from 'axios'

function install(): void {
  const authenticationStore = useAuthenticationStore()
  const { checkAuth0Session } = authenticationStore
  const { token, isAuthenticated } = storeToRefs(authenticationStore)

  const retryRequest = ref(true)

  axios.interceptors.request.use((request) => {
    if (isAuthenticated.value && request.headers) {
      request.headers.Authorization = `Bearer ${token.value}`
    }

    return request
  })

  axios.interceptors.response.use(
    (response) => {
      retryRequest.value = true

      return response
    },
    async (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        if (retryRequest.value) {
          try {
            await checkAuth0Session()

            return axios(error.response.config)
          } catch {
            // do nothing, user will be redirected
          } finally {
            retryRequest.value = false
          }
        }
      }

      return Promise.reject(error)
    }
  )
}

export default { install }
