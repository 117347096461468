import { useErrorGuard } from '@/shared/browser/guards/errorGuard'
import { constants } from '@/globals'

import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

export default {
  path: '/error',
  name: constants.ROUTES_STORAGE_ERROR,
  component: retriableLazyImport(() => import('../browser/pages/CookieWarning.vue')),
  beforeEnter: [
    useErrorGuard(),
  ],
} as RouteRecordRaw
