import { constants } from '@/globals'
import { useIsAdminGuard } from '@/shared/authorization'

import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

export default {
  path: 'settings',
  beforeEnter: [
    useIsAdminGuard(),
  ],
  children: [
    {
      path: 'users',
      component: retriableLazyImport(() => import('./pages/users/pages/Overview.vue')),
      name: constants.ROUTES_SETTINGS_USERS,
      meta: {
        navigation: {
          labelKey: 'app.settings.pages.users.pages.overview.title',
          targetName: constants.ROUTES_SETTINGS_USERS,
        },
      },
    },
    {
      path: 'users/:userId',
      component: retriableLazyImport(() => import('./pages/users/pages/Details.vue')),
      name: constants.ROUTES_SETTINGS_USER,
      meta: {
        navBarParent: constants.ROUTES_SETTINGS_USERS,
      },
    },
    {
      path: 'users/create',
      component: retriableLazyImport(() => import('./pages/users/pages/Details.vue')),
      name: constants.ROUTES_SETTINGS_USER_CREATE,
      meta: {
        navBarParent: constants.ROUTES_SETTINGS_USERS,
      },
    },
    {
      path: 'service-desk',
      component: retriableLazyImport(() => import('./pages/service-desk/pages/Overview.vue')),
      name: constants.ROUTES_SETTINGS_SERVICE_DESK,
      meta: {
        navigation: {
          labelKey: 'app.settings.pages.service_desk.pages.overview.title',
          targetName: constants.ROUTES_SETTINGS_SERVICE_DESK,
        },
      },
    },
  ],
} as RouteRecordRaw
