import { constants } from '@/globals'

import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

export default {
  path: '/onboarding',
  component: retriableLazyImport(() => import('./components/OnboardingLayout.vue')),
  children: [
    {
      path: ':iccid',
      name: constants.ROUTES_ONBOARDING,
      component: retriableLazyImport(() => import('./pages/OnboardingClaimStep.vue')),
    },
  ],
} as RouteRecordRaw
