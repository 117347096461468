import { constants } from '@/globals'

import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

export default {
  path: 'cloud-adapters',
  meta: {
    navigation: {
      labelKey: 'app.cloud_adapters.pages.overview.title',
      targetName: constants.ROUTES_CLOUD_ADAPTERS,
    },
  },
  children: [
    {
      path: '',
      component: retriableLazyImport(() => import('./pages/Overview.vue')),
      name: constants.ROUTES_CLOUD_ADAPTERS,
    },
    {
      path: ':cloudAdapterId',
      component: retriableLazyImport(() => import('./pages/Details.vue')),
      name: constants.ROUTES_CLOUD_ADAPTER,
    },
    {
      path: 'create/:type(mqtt|http)',
      component: retriableLazyImport(() => import('./pages/Details.vue')),
      name: constants.ROUTES_CLOUD_ADAPTER_CREATE,
    },
  ],
} as RouteRecordRaw
